import React, { useState } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';



const Inscription = () => {
    const navigate = useNavigate(); // Hook pour naviguer entre les pages

    const handleSuivantClick = () => {
        navigate('/inscriptionsuite'); // Rediriger vers la page d'inscription
      };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <div className="bg-white py-10 px-4 md:px-0 md:max-w-4xl mx-auto">
      <h1 className="text-center text-3xl font-bold mb-8">INSCRIPTION</h1>
      
      {/* Form Inputs */}
      <div className="space-y-6">
        <input
          type="text"
          placeholder="VOTRE NOM"
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />
        <input
          type="email"
          placeholder="VOTRE EMAIL"
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />
        <input
          type="text"
          placeholder="CREEZ UN IDENTIFIANT"
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />

        {/* Password Input */}
        <div className="relative">
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="CREEZ UN MOT DE PASSE"
            className="w-full border border-gray-400 p-4 rounded-lg italic"
          />
          <p className="absolute right-28 top-4 text-sm text-red-600">
            (8 Caractères minimum avec au moins une majuscule et un numéro)
          </p>
          <span
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-4 top-4 cursor-pointer"
          >
            {showPassword ? '👁️' : '👁️‍🗨️'}
          </span>
        </div>

        {/* Confirm Password Input */}
        <div className="relative">
          <input
            type={showConfirmPassword ? 'text' : 'password'}
            placeholder="CONFIRMER VOTRE MOT DE PASSE"
            className="w-full border border-gray-400 p-4 rounded-lg italic"
          />
          <span
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            className="absolute right-4 top-4 cursor-pointer"
          >
            {showConfirmPassword ? '👁️' : '👁️‍🗨️'}
          </span>
        </div>

        {/* Select Input */}
        <div>
        <div className="relative flex items-center space-x-4">
      <label className="font-semibold italic text-lg w-[30rem]">
        VOUS VOUS INSCRIVEZ EN TANT QUE :
      </label>

      {/* Dropdown container */}
      <div className="relative w-4/5">
        <select className="appearance-none border border-gray-400 p-4 rounded-lg italic w-full">
          <option>AUTEUR</option>
          <option>DIFFUSEUR</option>
        </select>

        {/* Flèche verte IoMdArrowDropdown */}
        <div className="absolute inset-y-0 right-4 flex items-center pointer-events-none">
          <IoMdArrowDropdown className="w-12 h-12 text-green-500" /> {/* Taille augmentée */}
        </div>
      </div>
    </div>
<p className="text-sm text-red-600 -mt-4">Plusieurs choix possibles</p>
</div>

        {/* Checkbox Inputs */}
        <div className="space-y-3">
  <label className="block flex items-center">
    <input type="checkbox" className="mr-2 w-6 h-6" />
    <span className='italic font-semibold'>OUI, j'accepte les <a href="#" className="text-blue-600 hover:underline">conditions générales d'utilisation de la plateforme</a></span>
  </label>
  <label className="block flex items-center">
    <input type="checkbox" className="mr-2 w-6 h-6" />
    <span className='italic font-semibold'>OUI, j'accepte la <a href="#" className="text-blue-600 hover:underline">politique de confidentialité</a></span>
  </label>
  <label className="block flex items-center">
    <input type="checkbox" className="mr-2 w-6 h-6" />
    <span className='italic font-semibold'>OUI, j'accepte le <a href="#" className="text-blue-600 hover:underline">contrat d'édition</a></span>
  </label>
</div>


        {/* Pricing Information */}
        <div className="flex justify-between items-center">
  <p className="text-lg font-bold">
    L'abonnement à la plateforme est à 29 C.<br />
    Ajouter 10 C par métier.
  </p>

  {/* Submit Button */}
  <button className="bg-green-600 text-white font-bold py-2 px-8 rounded-lg hover:bg-green-500 text-2xl" onClick={handleSuivantClick}>
    SUIVANT
  </button>
</div>

      </div>
    </div>
  );
};

export default Inscription;
