import React from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import { IoMdArrowDropright } from "react-icons/io";
import logo from '../img/logo_editions7_transparent.png';
import chat from '../img/chat.png';
import Header1 from './diffuseurs/Header1';



const Compte = () => {
  return (
    <div className="bg-white min-h-screen">
      {/* Header */}
      <Header1 />

      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          {/* Sidebar */}
         <div className="bg-white shadow-md rounded-lg p-4">
<ul className="space-y-4">
  <li className="group">
    <a href="/mon-compte" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Tableau de bord
    </a>
  </li>
  <li className="group">
    <a href="/mes-livres" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Mes livre
    </a>
  </li>
  <li className="group">
    <a href="/recherchre-diffuseurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Trouver des professionnels
    </a>
  </li>
  <li className="group">
    <a href="/mes-informations" className="font-bold text-gray-600 border border-green-500 rounded-md px-2 py-2 flex items-center">
    <span className="inline-block text-green-500 mr-2">
      <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
    </span>
Mes Informations    </a>
  </li>
  <li className="group">
    <a href="/mes-publications" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Mes publications
    </a>
  </li>
  <li className="group">
    <a href="/devis" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Devis Impression / demande
    </a>
  </li>
  <li className="group">
    <a href="/communication" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Conseil communication
    </a>
  </li>
  <li className="group">
    <a href="/book-sales" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Vente de livres
    </a>
  </li>
  
</ul>


</div>


          {/* Main Content */}
          <div className="md:col-span-3">
          <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
          <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500 w-3/4">MES INFORMATIONS</h2>

              {/* Form Inputs */}
              <form className="space-y-4">
              <div className="grid grid-cols-1 gap-6">
  {/* Civilité Section */}
  <div className="flex items-center space-x-4 gap-4">
  <label className="font-bold text-[#737373]">Civilité :</label>
  <label className="flex items-center text-[#737373] font-semibold">
    <input type="radio" name="civility" className="mr-2" /> Mr.
  </label>
  <label className="flex items-center text-[#737373] font-semibold">
    <input type="radio" name="civility" className="mr-2" /> Mme.
  </label>
</div>

  {/* Nom and Prénom Section */}
  <div className="grid grid-cols-2 gap-4">
  {/* Nom Section */}
  <div className="flex items-center space-x-2">
    <label className="font-bold text-[#737373]">Nom :</label>
    <input type="text" className="border border-gray-400 p-2 flex-1 rounded-md  w-[21.5rem]" />
  </div>

  {/* Prénom Section */}
  <div className="flex items-center space-x-2">
    <label className="font-bold text-[#737373]">Prénom :</label>
    <input type="text" className="border border-gray-400 p-2 flex-1 rounded-md  w-[21.5rem]" />
  </div>
</div>

</div>


<div className="grid grid-cols-3 gap-4">
  {/* Date de naissance Section */}
  <div className="flex items-center space-x-2">
    <label className="font-bold whitespace-nowrap text-[#737373]">Date de naissance :</label>
    <div className="flex items-center space-x-2">
      <div className="relative w-28">
        <select className="border border-gray-400 p-1.5 rounded-md appearance-none w-full h-8">
          <option>Jour</option>
        </select>
        <IoMdArrowDropdown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-green-500 w-9 h-9" /> {/* Larger green arrow icon */}
      </div>
      <div className="relative w-32">
        <select className="border border-gray-400 p-1.5 rounded-md appearance-none w-full h-8">
          <option>Mois</option>
        </select>
        <IoMdArrowDropdown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-green-500 w-9 h-9" /> {/* Larger green arrow icon */}
      </div>
      <div className="relative w-28">
        <select className="border border-gray-400 p-1.5 rounded-md appearance-none w-full h-8">
          <option>Année</option>
        </select>
        <IoMdArrowDropdown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-green-500 w-9 h-9" /> {/* Larger green arrow icon */}
      </div>
    </div>
  </div>
</div>


<div className="flex items-center space-x-2 gap-3">
  <label className="font-bold whitespace-nowrap text-[#737373]">Email :</label>
  <input type="email" className="border border-gray-400 p-2 rounded-md w-[21.5rem]" /> 
</div>



<div className="flex items-center space-x-2 gap-4">
  <label className="font-bold whitespace-nowrap text-[#737373]">Siret :</label>
  <input type="text" className="border border-gray-400 p-2 rounded-md w-[21.5rem]" /> 
</div>


<div className="flex items-center space-x-4 gap-8">
  <label className="font-bold text-[#737373]">TVA :</label>
  <label className="flex items-center text-[#737373] font-semibold">
    <input type="radio" name="tva" className="mr-2 " /> Assujetti
  </label>
  <label className="flex items-center ml-8 text-[#737373] font-semibold"> 
    <input type="radio" name="tva" className="mr-2 " /> Non assujetti
  </label>
</div>


              </form>
            </div>

            {/* Password Change Section */}
            <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
            <h2 className="text-xl font-bold mb-4  border-b-2 border-green-500 w-3/4 pb-2">Changer mot de passe :</h2>

  <form className="space-y-4">
    <div className="grid grid-cols-1 gap-6">
      <div className="flex items-center">
        <label className="font-bold w-56 text-[#737373]">Mot de passe Actuel :</label> 
        
        <input type="password" className="border border-gray-400 p-2 rounded-md  w-[21.5rem]" />
      </div>
      <div className="flex items-center">
        <label className="font-bold w-56 text-[#737373]">Nouveau Mot de Passe :</label> 
        <input type="password" className="border border-gray-400 p-2 rounded-md  w-[21.5rem]" />
      </div>
    </div>

    <div className="flex items-center">
      <label className="font-bold w-56 text-[#737373]">Confirmez Mot de Passe :</label> 
      <input type="password" className="border border-gray-400 p-2 rounded-md  w-[21.5rem]" />
    </div>
  </form>
</div>




          </div>
        </div>
      </div>
    </div>
  );
};

export default Compte;
