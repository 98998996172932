import React from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import { IoMdArrowDropright } from "react-icons/io";

import Header1  from './Header1';

const Facture = () => {
  // Sample data for the invoice table
  const invoices = [
    {
      codeProduit: "PRD001",
      commission: "15%",
      quantite: 10,
      punitaire: "25€",
      prix: "250€",
    },
    {
      codeProduit: "PRD002",
      commission: "10%",
      quantite: 5,
      punitaire: "30€",
      prix: "150€",
    },
  ];

  return (
    <div className="bg-white min-h-screen">
  <Header1 />
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          {/* Sidebar */}
          <div className="bg-white shadow-md rounded-lg p-4">
                        <ul className="space-y-4">
                            <li className="group">
                                <a href="/compte-diffuseur"   className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Tableau de bord
                                </a>
                            </li>
                            <li className="group">
                                <a href="/livres" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Mes livres
                                </a>
                            </li>
                            <li className="group">
                                <a href="/rechercher-auteurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Trouver des professionnels
                                </a>
                            </li>
                            <li className="group">
                                <a href="/information-diffuseur" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Mes Informations
                                </a>
                            </li>
                            <li className="group">
    <a href="/devis-diffuseur" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Devis Impression / demande
    </a>
  </li>
                            <li className="group">
                                <a href="/factures" className="font-bold text-gray-600 border border-green-500 rounded-md px-2 py-2 flex items-center">
    <span className="inline-block text-green-500 mr-2">
      <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
    </span>
                                    Mes factures                                </a>
                            </li>
    
  <li className="group">
                                <a href="/book-sales" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                   Mes Ventes
                                </a>
                            </li>
                 <li className="group">
                                <a href="/commandes" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Commandes
                                </a>
                            </li>
                          
                        </ul>
                    </div>
          {/* Main Content */}
          <div className="md:col-span-3">
            <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
              <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500 w-3/4">Mes Factures</h2>

              {/* Invoice Table */}
              <table className="table-auto w-full border-collapse border border-gray-400">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="border border-gray-300 p-2">CODE PRODUIT</th>
                    <th className="border border-gray-300 p-2">Commission</th>
                    <th className="border border-gray-300 p-2">QUANTITÉ</th>
                    <th className="border border-gray-300 p-2">Prix unitaire du livre</th>
                    <th className="border border-gray-300 p-2">Prix total</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices.map((invoice, index) => (
                    <tr key={index}>
                      <td className="border border-gray-300 p-2">{invoice.codeProduit}</td>
                      <td className="border border-gray-300 p-2">{invoice.commission}</td>
                      <td className="border border-gray-300 p-2">{invoice.quantite}</td>
                      <td className="border border-gray-300 p-2">{invoice.punitaire}</td>
                      <td className="border border-gray-300 p-2">{invoice.prix}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facture;
