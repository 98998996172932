import React from 'react';
import footerShape from '../img/footer.png'; // Ensure the correct path to the image
import { IoMdArrowDropright } from 'react-icons/io'; // Example of using a red arrow icon

const Footer = () => {
  return (
    <footer className="relative bg-[#00ae14] text-black">
      {/* Footer Wave Shape as Background */}
      <div className="absolute bottom-0 w-full">
        <img src={footerShape} alt="Footer Shape" className="w-full h-24 md:h-32 lg:h-80" />
      </div>

      {/* Footer Content centered */}
      <div className="relative z-10 py-16 flex flex-col justify-center items-center h-full text-center">
        <div className="container mx-auto">
          {/* Links with Even Larger Icons and Green Text */}
          <div className="mb-6 flex flex-col items-start  absolute left-0 pl-10 mt-32 ml-20"> {/* Adjusted position */}
            <div className="flex items-center">
              <IoMdArrowDropright className="text-red-500 text-5xl mr-2" /> {/* Larger Red arrow icon */}
              <a href="#" className="text-[#30bd40]  text-xl font-bold ">
                Conditions générales d'utilisation
              </a>
            </div>
            <div className="flex items-center">
              <IoMdArrowDropright className="text-red-500 text-5xl mr-2" /> {/* Larger Red arrow icon */}
              <a href="#" className="text-[#30bd40] font-bold text-xl">
                Charte RGPD
              </a>
            </div>
          </div>

          {/* Contact Information */}
          <div className="text-center mt-10">
            <h4 className="font-bold mb-2">NOUS CONTACTER :</h4>
            <p className='mb-2 font-semibold -ml-16' >EDITIONS 7</p>
            <p className='mb-2 font-semibold -ml-8'>Mr. Cyril MOREL</p>
            <p className='mb-2 font-semibold -ml-8'>
              <a href="mailto:contact@editions7.fr" className="hover:underline text-black ml-8">
                contact@editions7.fr
              </a>
            </p>
            <p className='mb-2 font-semibold -ml-12'>06 85 39 96 94</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
