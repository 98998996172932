import React, { useState } from 'react';
import { IoMdEyeOff, IoMdEye } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

const Connexion = () => {
  const navigate = useNavigate();
  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const authorAccount = {
    email: 'corinnet@hotmail.com',
    password: '123456',
  };

  const diffuseurAccount = {
    email: 'regis@hotmail.com',
    password: '123456',
  };

  const handleConnexionClick = () => {
    if (emailOrUsername === authorAccount.email && password === authorAccount.password) {
      localStorage.setItem('isLoggedIn', 'true'); // Store login state
      localStorage.setItem('userRole', 'author'); // Optional: Store user role
      navigate('/mon-compte'); // Redirect to Author dashboard
    } else if (emailOrUsername === diffuseurAccount.email && password === diffuseurAccount.password) {
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('userRole', 'diffuseur');
      navigate('/compte-diffuseur'); // Redirect to Diffuseur dashboard
    } else {
      alert('Identifiant ou mot de passe incorrect.');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="bg-white py-16 px-4 md:px-0 max-w-2xl mx-auto">
      <h1 className="text-center text-2xl font-bold mb-16 uppercase">
        Connectez-vous à votre compte :
      </h1>
      <div className="space-y-8">
        <div className="flex justify-between items-center">
          <label className="font-semibold text-lg text-left md:w-64">IDENTIFIANT OU EMAIL :</label>
          <input
            type="text"
            className="w-full md:w-2/3 border border-gray-300 p-3 rounded-lg"
            placeholder="Entrez votre identifiant ou email"
            value={emailOrUsername}
            onChange={(e) => setEmailOrUsername(e.target.value)}
          />
        </div>
        <div className="flex justify-between items-center">
          <label className="font-semibold text-lg text-left md:w-64">MOT DE PASSE :</label>
          <div className="relative w-full md:w-2/3">
            <input
              type={showPassword ? 'text' : 'password'}
              className="w-full border border-gray-300 p-3 rounded-lg"
              placeholder="Entrez votre mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span
              onClick={togglePasswordVisibility}
              className="absolute right-4 top-3 cursor-pointer text-2xl text-gray-500"
            >
              {showPassword ? <IoMdEye /> : <IoMdEyeOff />}
            </span>
          </div>
        </div>
        <div className="flex justify-between items-center w-full md:w-2/3 ml-56">
          <a href="#" className="text-blue-600 hover:underline text-sm">
            Mot de Passe perdu ?
          </a>
          <button
            className="bg-green-600 text-white font-bold py-3 px-8 rounded-lg text-lg hover:bg-green-500"
            onClick={handleConnexionClick}
          >
            Se connecter
          </button>
        </div>
      </div>
    </div>
  );
};

export default Connexion;
