import React from 'react';

const Fonctionnement = () => {
  return (
    <div className="bg-[#00ae14] min-h-screen text-white">
    
      {/* Main Content */}
      <div className="text-center py-16 px-8">
        <h1 className="text-4xl font-bold mb-10">Fonctionnement</h1>
        <p className="text-xl max-w-4xl mx-auto mb-12">
          Cette plateforme s’adresse à tous les professionnels de l'édition de livre.
          L’objectif est d’apporter des solutions pratiques et commerciales aux utilisateurs.
        </p>

        {/* Cards Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-8">
            {/* Card 1 */}
            <div className="bg-white text-black p-6 rounded-lg shadow-md">
              <h2 className="text-2xl font-bold mb-4 text-left">Les auteurs auront accès :</h2>
              <div className="space-y-2 text-left">
                <p>Trouver des centaines de diffuseurs qui commercialiseront votre livre.</p>
                <p>Un espace de communication avec les diffuseurs et autres professionnels.</p>
                <p>Un compte pour voir en temps réel vos ventes et vos commissions.</p>
                <p>Vos commissions sont de 30 % et vos droits d'auteur de 10 %.</p>
                <p>Un calculateur de coût d'impression de vos livres.</p>
              </div>
            </div>

            {/* Card 2 */}
            <div className="bg-white text-black p-6 rounded-lg shadow-md">
              <h2 className="text-2xl font-bold mb-4 text-left">Les diffuseurs auront accès :</h2>
              <div className="space-y-2 text-left">
                <p>Trouver des auteurs pour vendre leurs livres en points de vente.</p>
                <p>Un espace de communication avec les auteurs et autres professionnels.</p>
                <p>Un compte pour situer vos points de vente et suivre vos ventes.</p>
                <p>Vos commissions sont de 30 % et vos droits d'auteur de 10 %.</p>
                <p>Vous gagnerez 25 % du prix du livre vendu sans droits de retour.</p>
                <p>Un calculateur de coût d'impression de vos livres.</p>
              </div>
            </div>
          </div>

          {/* Card 3 */}
          <div className="bg-white text-black p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-4 text-left">Les autres professionnels auront accès :</h2>
            <div className="space-y-2 text-left">
              <p>Trouver des auteurs souhaitant un service de qualité pour leur ouvrage.</p>
              <p>Un espace de communication avec les auteurs via un chat et forum.</p>
            </div>
          </div>
        </div>
      </div>

      {/* White Space Section */}
      <div className="bg-white h-16"></div> {/* This adds the white space */}

      {/* Tarifs Section */}
      <div className="bg-[#00ae14] py-10 px-4 text-center text-white">
        {/* Title */}
        <h2 className="text-4xl font-bold mb-6">Tarifs</h2>
        <p className="mb-6 font-semibold text-xl">
          L’abonnement est de 29 € par mois pour tous, ajouter 10 € par métier complémentaire.<br />
          L'abonnement est résiliable à tout moment par tous les utilisateurs.
        </p>

        {/* Description */}
        <div className="my-6">
          <p className="text-xl underline font-semibold mb-2">Pour l'auteur</p>
          <p className="text-lg ">
            L’édition du livre court tant que les livres ne sont pas tous vendus. Le contrat d’édition est distinct<br />
            de l’abonnement pour l’utilisation du site.
          </p>
          <p className="text-xl underline font-semibold mt-4 mb-2">Pour le diffuseur</p>
          <p className="text-lg">
            Le contrat de diffusion est distinct de l’abonnement pour l’utilisation du site internet.
          </p>
        </div>

        {/* Tarifs d'impression Section */}
        <h2 className="bg-white text-green-600 py-2 px-4 text-2xl font-bold inline-block mb-6">
          TARIF D'IMPRESSION
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Tarif ROMAN 12.5 x 19.5 cm */}
          <div className="bg-white text-black p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-bold mb-4">ROMAN</h3>
            <div className="space-y-2 text-left">
              <p>Format 12.5 x 19.5 cm, 200 pages.</p>
              <p>Impression noire recto verso, Papier offset blanc 80g/m2.</p>
              <ul className="mt-4 space-y-2">
                <li>100 ex : 480 € soit 4.80 €/unité</li>
                <li>200 ex : 836 € soit 4.18 €/unité</li>
                <li>500 ex : 1.750 € soit 3.50 €/unité</li>
                <li>1.000 ex : 2.980 € soit 2.98 €/unité</li>
              </ul>
            </div>
          </div>

          {/* Tarif ESSAI HISTORIQUE OU SCIENTIFIQUE 12.5 x 19.5 cm */}
          <div className="bg-white text-black p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-bold mb-4">ESSAI HISTORIQUE OU SCIENTIFIQUE</h3>
            <div className="space-y-2 text-left">
              <p>Format 12.5 x 19.5 cm, 200 pages.</p>
              <p>Impression quadrichromie recto verso, Papier offset blanc 120g/m2.</p>
              <ul className="mt-4 space-y-2">
                <li>100 ex : 1.144 € soit 11.44 €/unité</li>
                <li>200 ex : 1.900 € soit 9.55 €/unité</li>
                <li>500 ex : 3.605 € soit 7.21 €/unité</li>
                <li>1.000 ex : 6.170 € soit 6.17 €/unité</li>
              </ul>
            </div>
          </div>

          {/* Tarif ROMAN 15 x 21 cm */}
          <div className="bg-white text-black p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-bold mb-4">ROMAN</h3>
            <div className="space-y-2 text-left">
              <p>Format 15 x 21 cm, 250 pages.</p>
              <p>Impression noire recto verso, Papier blanc ou ivoire bouffant 90 g/m2.</p>
              <ul className="mt-4 space-y-2">
                <li>100 ex : 562 € soit 5.62 €/unité</li>
                <li>200 ex : 1.086 € soit 5.43 €/unité</li>
                <li>500 ex : 2.210 € soit 4.42 €/unité</li>
                <li>1.000 ex : 3.700 € soit 3.70 €/unité</li>
              </ul>
            </div>
          </div>

          {/* Tarif ESSAI HISTORIQUE OU SCIENTIFIQUE 15 x 21 cm */}
          <div className="bg-white text-black p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-bold mb-4">ESSAI HISTORIQUE OU SCIENTIFIQUE</h3>
            <div className="space-y-2 text-left">
              <p>Format 15 x 21 cm, 250 pages.</p>
              <p>Impression quadrichromie recto verso, Papier 115 g/m2 brillant.</p>
              <ul className="mt-4 space-y-2">
                <li>100 ex : 1.350 € soit 13.50 €/unité</li>
                <li>200 ex : 2.200 € soit 11.00 €/unité</li>
                <li>500 ex : 4.180 € soit 8.36 €/unité</li>
                <li>1.000 ex : 7.300 € soit 7.30 €/unité</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Delivery Section */}
        <div className="bg-white text-black p-4 rounded-lg shadow-md mt-8">
          <h4 className="text-lg font-bold">LIVRAISON PAR ADRESSE FRANCE METROPOLITAINE : 25 €</h4>
          <p>(forfait unique : de 1 à 1 000 livres)</p>
          <p className="text-sm mt-2">*détail pour la réalisation en condition générales d’utilisation de la plateforme</p>
        </div>
      </div>
    </div>
  );
};

export default Fonctionnement;
