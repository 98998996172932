import React, { useState } from 'react';
import { IoMdCreate, IoMdTrash, IoMdArrowDropright } from 'react-icons/io';
import Header1 from './diffuseurs/Header1';

const Publication = () => {
    const [showModal, setShowModal] = useState(false); // État pour afficher le modal d'ajout
    const [showEditModal, setShowEditModal] = useState(false); // État pour afficher le modal d'édition
    const [selectedBook, setSelectedBook] = useState({}); // Stocke les données du livre à éditer
    const [showPublications, setShowPublications] = useState(false); // État pour afficher le tableau des publications

    // Dummy data for publications
    const publications = [
        {
            type: 'Roman',
            edition: '3',
            auteur: 'John Doe',
            titre: 'Le Grand Voyage',
            versionPapier: 'Disponible',
            versionNumerique: 'Disponible',
        },
        {
            type: 'Essai',
            edition: '1',
            auteur: 'Jane Smith',
            titre: 'Réflexions modernes',
            versionPapier: 'Non disponible',
            versionNumerique: 'Disponible',
        },
        // Ajouter d'autres publications ici...
    ];

    // Affiche le tableau des publications lorsque l'utilisateur clique sur "Mes publications"
    const handleShowPublications = () => {
        setShowPublications(true);
    };

    // Affiche le modal d'ajout de livre
    const handleAddBook = () => {
        setShowModal(true);
    };

    // Affiche le modal d'édition avec les données du livre sélectionné
    const handleEditBook = (book) => {
        setSelectedBook(book); // Stocke les données du livre sélectionné
        setShowEditModal(true); // Ouvre le modal d'édition
    };

    const handleSubmitBook = (event) => {
        event.preventDefault();
        // Logique pour soumettre le formulaire d'ajout
        alert('Livre ajouté avec succès !');
        setShowModal(false); // Fermer le modal après la soumission
    };

    const handleSubmitEditBook = (event) => {
        event.preventDefault();
        // Logique pour soumettre les modifications
        alert('Livre modifié avec succès !');
        setShowEditModal(false); // Fermer le modal après la soumission
    };

    const handleCloseModal = () => {
        setShowModal(false); // Fermer le modal d'ajout
    };

    const handleCloseEditModal = () => {
        setShowEditModal(false); // Fermer le modal d'édition
    };

    return (
        <div className="bg-white min-h-screen">
            {/* Header */}
            <Header1 />

            <div className="container mx-auto px-4 py-8">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                         {/* Sidebar */}
         <div className="bg-white shadow-md rounded-lg p-4">
<ul className="space-y-4">
  <li className="group">
    <a href="/mon-compte" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Tableau de bord
    </a>
  </li>
  <li className="group">
    <a href="/mes-livres" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Mes livre
    </a>
  </li>
  <li className="group">
    <a href="/recherchre-diffuseurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Trouver des professionnels
    </a>
  </li>
  <li className="group">
    <a href="/mes-informations" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
Mes Informations    </a>
  </li>
  <li className="group">
    <a href="/publications" className="font-bold text-gray-600 border border-green-500 rounded-md px-2 py-2 flex items-center">
    <span className="inline-block text-green-500 mr-2">
      <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
    </span>
      Mes publications
    </a>
  </li>
  <li className="group">
    <a href="/devis" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Devis Impression / demande
    </a>
  </li>
  <li className="group">
    <a href="/communication" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Conseil communication
    </a>
  </li>
  <li className="group">
    <a href="/book-sales" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Vente de livres
    </a>
  </li>
  
</ul>


</div>

                    {/* Main Content */}
                    <div className="md:col-span-3">
          <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
          <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500 w-3/4">MES PUBLICATIONS</h2>

          <table className="table-auto border-collapse border border-gray-400 w-full">
                                    <thead>
                                        <tr className="bg-gray-200">
                                            <th className="border border-gray-300 p-2">Type</th>
                                            <th className="border border-gray-300 p-2">N° d'édition</th>
                                            <th className="border border-gray-300 p-2">Auteur</th>
                                            <th className="border border-gray-300 p-2">Titre</th>
                                            <th className="border border-gray-300 p-2">Version papier</th>
                                            <th className="border border-gray-300 p-2">Version numérique</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {publications.map((publication, index) => (
                                            <tr key={index}>
                                                <td className="border border-gray-300 p-2">{publication.type}</td>
                                                <td className="border border-gray-300 p-2">{publication.edition}</td>
                                                <td className="border border-gray-300 p-2">{publication.auteur}</td>
                                                <td className="border border-gray-300 p-2">{publication.titre}</td>
                                                <td className="border border-gray-300 p-2">{publication.versionPapier}</td>
                                                <td className="border border-gray-300 p-2">{publication.versionNumerique}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
            </div>

     




          </div>
                </div>
            </div>
        </div>
    );
};

export default Publication;
