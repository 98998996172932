import React, { useState } from 'react';
import stripe from '../img/logo-stripe.png';
import { useNavigate } from 'react-router-dom';
import { IoMdEye } from 'react-icons/io'; // Eye icon for show

const InscriptionSuite = () => {
  const navigate = useNavigate();
  const [fileName, setFileName] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };

  return (
    <div className="bg-white py-10 px-4 md:px-0 md:max-w-4xl mx-auto">
      <div className="space-y-6">
        {/* Photo de profil */}
        <div className="flex items-center space-x-4">
          <label className="text-lg font-bold">VOTRE PHOTO DE PROFIL :</label>

          {/* Input pour fichier (caché) */}
          <input
            type="file"
            id="fileInput"
            className="hidden"
            onChange={handleFileChange}
          />

          {/* Bouton pour choisir un fichier */}
          <button
            className="bg-[#38dcaa] text-white py-2 px-4  hover:bg-[#38dcaa] cursor-pointer"
            onClick={() => document.getElementById('fileInput').click()}
          >
            Choisissez un fichier
          </button>

          {/* Affichage du nom du fichier sélectionné */}
          {fileName && <span className="text-gray-700 italic">{fileName}</span>}
        </div>

        {/* Adresse de facturation */}
        <input
          type="text"
          placeholder="VOTRE ADRESSE DE FACTURATION"
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />
        <input
          type="text"
          placeholder="COMMUNE"
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />
        <input
          type="text"
          placeholder="CODE POSTAL"
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />
        <input
          type="text"
          placeholder="TELEPHONE"
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />
        <input
          type="text"
          placeholder="SITE INTERNET"
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />

        {/* Réglez votre adhésion */}
        <div>
          <p className="font-bold mb-4">REGLEZ VOTRE ADHESION :</p>
          <p className="italic text-sm font-semibold">
            L'abonnement à la plateforme est à 29 € + 10 € par métier complémentaire, vous serez 
            <br />
            redirigé sur notre plateforme de règlement STRIPE
          </p>
          <img
            src={stripe}
            alt="Stripe Payment"
            className="w-1/2 mx-auto my-4"
          />
        </div>

       {/* Nouveau Boutons en format lien avec icône "show" */}
       <div className="space-y-4 mt-6 -ml-4"> {/* Stack buttons vertically with space */}
          <a
            href="/conditions-generales"
            className="flex items-center text-red-600 font-semibold py-2 px-4 rounded-md hover:text-green-700 transition duration-200 w-[50%] -mb-4"
            target="_blank" // Opens link in a new tab
            rel="noopener noreferrer"
          >
            <IoMdEye className="mr-2" /> {/* Eye icon on the left */}
            Consulter les conditions générales
          </a>
          <a
            href="/contrat-partenariat"
            className="flex items-center text-red-600 font-semibold py-2 px-4 rounded-md hover:text-green-700 transition duration-200 w-[50%]"
            target="_blank" // Opens link in a new tab
            rel="noopener noreferrer"
          >
            <IoMdEye className="mr-2" /> {/* Eye icon on the left */}
            Consulter le contrat de partenariat
          </a>
        </div>

        {/* Boutons */}
        <div className="flex justify-between mt-8">
          <button
            className="bg-red-600 text-white font-bold py-4 px-12 rounded-lg hover:bg-red-500"
            onClick={() => navigate(-1)}
          >
            RETOUR
          </button>
          <button className="bg-green-600 text-white font-bold py-4 px-12 rounded-lg hover:bg-green-500">
            S'INSCRIRE
          </button>
        </div>
      </div>
    </div>
  );
};

export default InscriptionSuite;
