import React from 'react';
import { useNavigate } from 'react-router-dom';

const CommencezIci = () => {
  const navigate = useNavigate(); // Hook for navigating between pages
  const handleCommencezClick = () => {
    navigate('/inscription'); // Redirect to the signup page
  };

  return (
    <div className="bg-[#00ae14] text-white">
      <div className="container mx-auto px-4 py-10">
        <h1 className="text-3xl md:text-4xl lg:text-4xl font-bold mb-11 text-center">
          Cette plateforme vous permettra de vendre <br />
          + de 1 000 exemplaires de votre livre
        </h1>

        <p className="text-lg md:text-xl lg:text-2xl text-center">
          Vous avez édité votre livre sur une des meilleures plateformes d’édition de livre en ligne, 
          et après 
        </p>
        <p className="text-lg md:text-xl lg:text-2xl text-center mb-11">
          1 an vous vous rendez compte qu’il vous reste 3/4 des livres chez vous ? 
          300 livres sur 400 ?
        </p>
        <h2 className="text-2xl md:text-3xl font-bold text-center">
          Vos livres ne se vendent pas ?
        </h2>
        <p className="text-xl md:text-3xl font-bold text-center mb-11">
          Pourtant, on vous a annoncé que le livre serait dans <br />
          + de 5 000 librairies, sur Amazon et à la Fnac.
        </p>

        <h3 className="text-2xl md:text-xl font-bold mb-4 text-center">
          Pourquoi n’avez-vous vendu que 100 livres ?
        </h3>
        <p className="text-xl md:text-3xl font-bold text-center mb-11">
          La raison est triple :
        </p>

        <ul className="list-none text-lg md:text-xl lg:text-2xl text-left md:text-center mb-10">
          <li>Le livre est référencé dans 5 000 librairies et n’est pas physiquement en librairie.</li>
          <li>Pour qu’il puisse être vendu, il faut qu’il soit aperçu dans la librairie.</li>
          <li>Il sera acheté uniquement si quelqu’un demande à le commander.</li>
        </ul>

        <p className="text-lg md:text-xl lg:text-2xl mb-10 text-center">
          Et comment le commander sans savoir s’il existe ? 
          <br />
          Prenons Fnac.com et Amazon.fr : j’adore le polar et je souhaite un nouveau livre. 
          <br />
          Combien vais-je trouver de livres correspondant à ma recherche ? 
          10, 100, 500, 1000 ?
        </p>

        <p className="text-lg md:text-xl lg:text-2xl mb-10 text-center font-bold">
          Vous avez sûrement pensé à une dizaine de points de vente autour de chez vous. 
          <br />
          Cette fois-ci, il est présent en librairies, maisons de presse et autres points de vente. 
          <br />
          Vous avez vendu 80 livres de cette manière.
        </p>

        <p className="text-xl md:text-2xl lg:text-3xl font-bold mb-10 text-center">
          Vous avez raison ! Tout ce que vous avez réalisé était à faire !
        </p>

        <p className="text-lg md:text-xl lg:text-2xl mb-10 text-center font-bold">
          Maintenant, imaginez que vous choisissiez une plateforme où l'on 
          <br />
          référence les titres sur 5 000 librairies, qu’il soit en vente sur le site de l’éditeur, 
          <br />
          sur Fnac.com et Amazon.fr...
          <br />
          Et
          <br />
          que vous ayez la possibilité de trouver + de 100 diffuseurs répartis sur toute la France.
        </p>

        <p className="text-lg md:text-xl lg:text-2xl text-center">
          Admettons que 20 diffuseurs acceptent de vendre votre livre 
        </p>
        <p className="text-lg md:text-xl lg:text-2xl mb-10 text-center">
          sera vendu à 5 points de vente de leur réseau.
        </p>
        <p className="text-lg md:text-xl lg:text-2xl mb-9 text-center">
          Ils écouleront raisonnablement 10 livres 
          pendant les trois premiers mois par points de vente.
        </p>
        <h3 className="text-xl md:text-2xl lg:text-3xl font-bold mb-10 text-center">
          Donc 20 x 5 x 10 = 1 000 livres !
        </h3>

        <p className="text-lg md:text-xl lg:text-2xl mb-10 text-center">
          Je n’ai pas fait de tout de magie.
          <br />
          20 diffuseurs qui ont 5 points de vente dans lesquels ils ont vendu 10 livres sur 3 mois.
        </p>

        <h2 className="text-xl md:text-2xl lg:text-3xl font-bold mb-10 text-center">
          Editions7 est cette plateforme !
        </h2>

        <p className="text-lg md:text-xl lg:text-2xl mb-10 text-center">
          Les auteurs-diffuseurs seront rémunérés 25 % du prix de vente du livre par Editions7 
          <br />
          (vente sans droit de retour) et 20 % (avec droit de retour).
        </p>

        <p className="text-lg md:text-xl lg:text-2xl mb-11 text-center">
          L’auteur gagnera 40 % (30 % en commission + 10 % en droits d’auteur). 
          <br />
          Editions7 obtiendra 5 % du prix du livre.
        </p>

        <h2 className="text-xl md:text-2xl lg:text-3xl font-bold mb-4 text-center">
          Ce principe de diffusion globale sur la France est UNIQUE.
        </h2>

        <p className="text-lg md:text-xl lg:text-2xl mb-4 font-bold text-center">
          Editions7 a l’ambition de réunir :
        </p>

        <ul className="list-none font-bold text-lg md:text-xl lg:text-2xl text-left md:text-center mb-10">
          <li>+ de 1 000 auteurs-diffuseurs</li>
          <li>+ de 4 000 auteurs</li>
          <li>+ de 2 000 autres professionnels</li>
        </ul>

        {/* Button */}
        <div className="flex justify-center">
  <button className="bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-10 rounded-[1rem] text-3xl"onClick={handleCommencezClick}>
    COMMENCEZ
  </button>
</div>

      </div>
    </div>
  );
};

export default CommencezIci;
