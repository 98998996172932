import React from 'react';
import { IoMdArrowDropright } from 'react-icons/io';

import Header1 from './diffuseurs/Header1';

const Dashboard = () => {
    const userName = "Corinne MATHIEU"; // Nom de l'utilisateur connecté

    return (
        <div className="bg-white min-h-screen">
            {/* Header */}
            <Header1 />

            <div className="container mx-auto px-4 py-8">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                   {/* Sidebar */}
                   <div className="bg-white shadow-md rounded-lg p-4">
                        <ul className="space-y-4">
                            <li className="group">
                                <a href="/mon-compte"  className="font-bold text-gray-600 border border-green-500 rounded-md px-2 py-2 flex items-center">
    <span className="inline-block text-green-500 mr-2">
      <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
    </span>
                                    Tableau de bord
                                </a>
                            </li>
                            <li className="group">
                                <a href="/mes-livres" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Mes livres
                                </a>
                            </li>
                            <li className="group">
                                <a href="/recherchre-diffuseurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Trouver des professionnels
                                </a>
                            </li>
                            <li className="group">
                                <a href="/mes-informations" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Mes Informations
                                </a>
                            </li>
                            <li className="group">
                                <a href="/mes-publications" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Mes publications
                                </a>
                            </li>
                            <li className="group">
    <a href="/devis" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Devis Impression / demande
    </a>
  </li>
                 <li className="group">
                                <a href="/communication" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Conseil communication
                                </a>
                            </li>
                            <li className="group">
                                <a href="/book-sales" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Vente de livres
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Main Content */}
                    <div className="md:col-span-3">
                        <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">

                            {/* Message de bienvenue */}
                            <p className="text-lg mb-4">
                                Bonjour <strong>{userName}</strong> (vous n'êtes pas <strong>{userName}</strong>? <a href="/logout" className="text-blue-600">Déconnexion</a>)
                            </p>

                            {/* Introduction */}
                            <p className="text-sm mb-4">
                                À partir du tableau de bord de votre compte, vous pouvez visualiser vos <a href="/recent-orders" className="text-blue-600">commandes récentes</a>, gérer vos <a href="/delivery-addresses" className="text-blue-600">adresses de livraison et de facturation</a> ainsi que <a href="/change-password" className="text-blue-600">changer votre mot de passe</a> et <a href="/account-details" className="text-blue-600">les détails de votre compte</a>.
                            </p>

                            {/* Section principale */}
                            <h3 className="text-md font-bold mb-4">Pour commencer votre projet d'édition de livre, je vous conseille :</h3>
                            <ol className="list-decimal pl-4 space-y-4">
                                <li>
                                    Déterminer le coût d'impression de votre ouvrage :
                                    <ul className="list-disc pl-6">
                                        <li><label>Cliquer sur "demander un devis d'impression" </label></li>
                                    </ul>
                                </li>
                                <li>
                                    Si vous avez des besoins de correction orthographique, mise en page, illustration, relation presse :
                                    <ul className="list-disc pl-6">
                                        <li> <label>Cliquer sur "Trouver des professionnels"</label></li>
                                    </ul>
                                </li>
                                <li>
                                    Trouver des diffuseurs pour vendre vos livres dans les points de vente : librairies, maisons de presse, etc. :
                                    <ul className="list-disc pl-6">
                                        <li><label>Cliquer sur "Trouver des professionnels"</label></li>
                                    </ul>
                                </li>
                                <li>
                                    À tout moment, vous pouvez échanger avec les membres de la plateforme :
                                    <ul className="list-disc pl-6">
                                        <li><label>Cliquer sur "Discuter avec les membres"</label></li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
