import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom'; // Add Navigate for redirection
import Header from './components/Header';
import Footer from './components/Footer';
import Accueil from './pages/Acceuil';
import CommencezIci from './pages/Comencer-ici';  
import Inscription from './pages/Inscription';
import Inscription2 from './pages/inscription2';
import Connexion from './pages/connexion';
import Fonctionnement from './pages/Fonctionnement';
import Compte from './pages/Compte';
import Livre from './pages/Livre';
import Publication from './pages/Publication';
import Devis from './pages/Devis';
import Dashboard from './pages/Dashboard';
import TrouverDesProfessionnels from './pages/trouverdiffuseurs';
import CompteD from './pages/diffuseurs/compte';
import INFOD from './pages/diffuseurs/info';
import Facture from './pages/diffuseurs/facture';
import TrouverDesAuteurs from './pages/diffuseurs/trouverPro';
import LivreD from './pages/diffuseurs/livre';
import DevisD from './pages/diffuseurs/devis';

function App() {
  const location = useLocation(); // Hook to get the current location

  // Pages où le Header doit être caché
    const hideHeaderRoutes = ['/mon-compte', '/mes-livres', '/mes-publications', '/devis' ,'/mes-informations','/recherchre-diffuseurs','/compte-diffuseur','/information-diffuseur','/factures','/rechercher-auteurs','/livres','/devis-diffuseur'];

  return (
    <div>
      {/* Cacher le Header pour les routes spécifiées */}
      {!hideHeaderRoutes.includes(location.pathname) && <Header />}

      <Routes>
        {/* Redirect from root '/' to '/Acceuil' */}
        <Route path="/" element={<Navigate to="/Acceuil" />} />  {/* Redirect root to Accueil */}
        <Route path="/Acceuil" element={<Accueil />} />  
        <Route path="/commencez-ici" element={<CommencezIci />} />  
        <Route path="/inscription" element={<Inscription />} />  
        <Route path="/inscriptionsuite" element={<Inscription2 />} />  
        <Route path="/login" element={<Connexion />} />  
        <Route path="/fonctionnement" element={<Fonctionnement />} /> 
        <Route path="/mes-informations" element={<Compte />} /> 
        <Route path="/mes-livres" element={<Livre />} /> 
        <Route path="/mes-publications" element={<Publication />} /> 
        <Route path="/devis" element={<Devis />} /> 
        <Route path="/mon-compte" element={<Dashboard />} /> 
        <Route path="/recherchre-diffuseurs" element={<TrouverDesProfessionnels />} /> 
        <Route path="/compte-diffuseur" element={<CompteD />} /> 
        <Route path="/information-diffuseur" element={<INFOD />} /> 
        <Route path="/factures" element={<Facture />} /> 
        <Route path="/rechercher-auteurs" element={<TrouverDesAuteurs />} /> 
        <Route path="/livres" element={<LivreD />} /> 
        <Route path="/devis-diffuseur" element={<DevisD />} /> 


      </Routes>

      <Footer />
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
