import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { IoMdArrowDropright } from 'react-icons/io';

import Header1 from './diffuseurs/Header1';

// Custom icons for different categories
const defaultUserIcon = new L.Icon({
  iconUrl: 'https://cdn-icons-png.flaticon.com/512/64/64572.png', // Black dot icon for all users
  iconSize: [15, 15],
});

const diffuseurIcon = new L.Icon({
  iconUrl: 'https://cdn-icons-png.flaticon.com/512/3064/3064231.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

const graphisteIcon = new L.Icon({
  iconUrl: 'https://cdn-icons-png.flaticon.com/512/149/149071.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

const pointSaleIcon = new L.Icon({
  iconUrl: 'https://cdn-icons-png.flaticon.com/512/2026/2026395.png', // Green for served points of sale
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

const pointSalePotentialIcon = new L.Icon({
  iconUrl: 'https://cdn-icons-png.flaticon.com/512/149/149071.png', // Yellow for potential points of sale
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

// Example data for professionals and points of sale
const professionals = [
  { name: "Diffuseur 1", lat: 48.8566, lng: 2.3522, type: "Diffuseur", city: "Paris", region: "Île-de-France", country: "France" },
  { name: "Graphiste 1", lat: 45.764, lng: 4.8357, type: "Graphiste", city: "Lyon", region: "Auvergne-Rhône-Alpes", country: "France" },
  { name: "Auteur 1", lat: 43.7102, lng: 7.2620, type: "Auteur", city: "Nice", region: "Provence-Alpes-Côte d'Azur", country: "France" },
];

const pointsOfSale = [
  { name: "Librairie 1", lat: 48.8586, lng: 2.3472, type: "Librairie", city: "Paris", region: "Île-de-France", country: "France", served: true },
  { name: "Maison de Presse 1", lat: 45.76, lng: 4.84, type: "Presse", city: "Lyon", region: "Auvergne-Rhône-Alpes", country: "France", served: false },
];

const TrouverDesProfessionnels = () => {
  const [selectedProfession, setSelectedProfession] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [viewType, setViewType] = useState('all-users'); // Controls which type of view to show

  const professions = [
    'Diffuseurs (Mandataire d\'édition)',
    'Graphistes',
    'Illustrateurs',
    'Traducteurs',
    'Chargé d\'édition',
    'Community Manager',
    'Chargé de relation presse',
    'Auteurs',
  ];

  const regions = ['Île-de-France', 'Auvergne-Rhône-Alpes', 'Nouvelle-Aquitaine', 'Provence-Alpes-Côte d\'Azur'];
  const cities = ['Paris', 'Lyon', 'Marseille'];
  const countries = ['France', 'Belgique', 'Luxembourg', 'Suisse'];

  const handleSearch = () => {
    setSearchTriggered(true);
  };

  const filterResults = () => {
    return professionals.filter(pro =>
      (!selectedProfession || pro.type === selectedProfession) &&
      (!selectedRegion || pro.region === selectedRegion) &&
      (!selectedCity || pro.city === selectedCity) &&
      (!selectedCountry || pro.country === selectedCountry)
    );
  };

  const filterPointsOfSale = () => {
    return pointsOfSale.filter(point =>
      (!selectedRegion || point.region === selectedRegion) &&
      (!selectedCity || point.city === selectedCity) &&
      (!selectedCountry || point.country === selectedCountry)
    );
  };

  return (
    <div className="bg-white min-h-screen">
      {/* Header */}
      <Header1 />

      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
             {/* Sidebar */}
             <div className="bg-white shadow-md rounded-lg p-4">
<ul className="space-y-4">
  <li className="group">
    <a href="/mon-compte" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Tableau de bord
    </a>
  </li>
  <li className="group">
    <a href="/mes-livres" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Mes livre
    </a>
  </li>
  <li className="group">
    <a href="/recherchre-diffuseurs"className="font-bold text-gray-600 border border-green-500 rounded-md px-2 py-2 flex items-center">
    <span className="inline-block text-green-500 mr-2">
      <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
    </span>
      Trouver des professionnels
    </a>
  </li>
  <li className="group">
                                <a href="/mes-informations" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Mes Informations
                                </a>
                            </li>
  <li className="group">
    <a href="/mes-publications" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Mes publications
    </a>
  </li>
  <li className="group">
    <a href="/devis" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Devis Impression / demande
    </a>
  </li>
  <li className="group">
    <a href="/communication" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Conseil communication
    </a>
  </li>
  <li className="group">
    <a href="/book-sales" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Vente de livres
    </a>
  </li>
  
</ul>


</div>

          {/* Main Content */}
          <div className="md:col-span-3">
            <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
              <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500">Trouver des Professionnels</h2>

              {/* Profession Search */}
              <div className="mb-4">
                <label className="block font-bold text-[#737373] mb-2">Rechercher par typologie de métier :</label>
                <select
                  className="border border-gray-300 p-2 w-full rounded-md"
                  value={selectedProfession}
                  onChange={(e) => setSelectedProfession(e.target.value)}
                >
                  <option value="">Sélectionner un métier</option>
                  {professions.map((profession) => (
                    <option key={profession} value={profession}>
                      {profession}
                    </option>
                  ))}
                </select>
              </div>

              {/* Geographic Search */}
              <div className="mb-4">
                <label className="block font-bold text-[#737373] mb-2">Rechercher géographiquement :</label>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <select
                    className="border border-gray-300 p-2 w-full rounded-md"
                    value={selectedRegion}
                    onChange={(e) => setSelectedRegion(e.target.value)}
                  >
                    <option value="">Sélectionner une région</option>
                    {regions.map((region) => (
                      <option key={region} value={region}>
                        {region}
                      </option>
                    ))}
                  </select>
                  <select
                    className="border border-gray-300 p-2 w-full rounded-md"
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                  >
                    <option value="">Sélectionner une ville</option>
                    {cities.map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                  <select
                    className="border border-gray-300 p-2 w-full rounded-md"
                    value={selectedCountry}
                    onChange={(e) => setSelectedCountry(e.target.value)}
                  >
                    <option value="">Sélectionner un pays</option>
                    {countries.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Search Button */}
              <div className="flex justify-end mb-4">
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                  onClick={handleSearch}
                >
                  Rechercher
                </button>
              </div>

              {/* Map and Results */}
              {searchTriggered && (
                <div className="mt-8">
                  <h3 className="text-lg font-bold mb-4">Résultats de la recherche :</h3>
                  <MapContainer
                    center={[48.8566, 2.3522]} // Initial center of the map (Paris)
                    zoom={5}
                    style={{ height: "500px", width: "100%" }}
                  >
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />

                    {/* Displaying Professional Markers */}
                    {filterResults().map((pro, index) => (
                      <Marker
                        key={index}
                        position={[pro.lat, pro.lng]}
                        icon={
                          selectedProfession ? (pro.type === "Diffuseur" ? diffuseurIcon : graphisteIcon) : defaultUserIcon
                        }
                      >
                        <Popup>
                          <strong>{pro.name}</strong><br />
                          {pro.type}<br />
                          {pro.city}
                        </Popup>
                      </Marker>
                    ))}

                    {/* Displaying Points of Sale */}
                    {filterPointsOfSale().map((point, index) => (
                      <Marker
                        key={index}
                        position={[point.lat, point.lng]}
                        icon={point.served ? pointSaleIcon : pointSalePotentialIcon}
                      >
                        <Popup>
                          <strong>{point.name}</strong><br />
                          {point.type}<br />
                          {point.city}
                        </Popup>
                      </Marker>
                    ))}
                  </MapContainer>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrouverDesProfessionnels;
