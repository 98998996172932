import React from 'react';
import image from '../img/Base-Vertmrmorel.jpg';
import { useNavigate } from 'react-router-dom';

const Accueil = () => {
  const navigate = useNavigate(); // Hook for navigating between pages
  const handleCommencezClick = () => {
    navigate('/inscription'); // Redirect to the signup page
  };

  return (
    <div className="bg-[#00ae14] text-white py-10">
      <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row items-start space-y-6 md:space-y-0 md:space-x-10 px-4 md:px-10 lg:px-20"> {/* Adjusted container and padding */}
        {/* Photo à gauche */}
        <div className="flex-shrink-0 rounded-full overflow-hidden w-60 h-60 md:w-[22rem] md:h-[22rem] xl:w-[27rem] xl:h-[27rem] border-2 border-white mt-10 md:mt-32 mx-auto md:mx-0 "> {/* Center image and remove excessive margins */}
          <img src={image} alt="Profile" className="w-full h-full object-cover" />
        </div>

        {/* Texte à droite */}
        <div className="md:flex-1 text-left mt-10"> {/* Adjusted margins */}
          <h1 className="text-2xl md:text-3xl font-bold">
            Devenez un auteur à succès :
          </h1>
          <p className="text-2xl md:text-3xl font-bold">
            Notre plateforme vous permettra de vendre 
          </p>
          <p className="text-2xl md:text-3xl font-bold mb-12">
            + de 1 000 exemplaires de votre livre !
          </p>
          <p className="text-2xl">
            Oubliez les plateformes d’autoédition 
          </p>
          <p className="mb-12 text-2xl">
            traditionnelles qui ne diffusent pas votre livre.
          </p>
          <p className="text-2xl md:text-3xl font-bold">
            Misez sur une communauté de 1000
          </p>
          <p className="text-2xl md:text-3xl font-bold">
            auteurs/diffuseurs répartie en France,
          </p>
          <p className="text-2xl md:text-3xl font-bold mb-12">
            Belgique, Luxembourg, Suisse, Québec.
          </p>
          <p className="text-2xl">
            Rejoignez-nous pour augmenter le nombre de librairies,
          </p>
          <p className="text-2xl">
            maisons de presse, musées, etc... 
          </p>
          <p className="text-2xl">
            Où sera présent votre livre.
          </p>

          {/* Button aligned to the right */}
          <div className="mt-8 flex justify-end">
            <button className="bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-6 rounded-lg text-3xl text-left"onClick={handleCommencezClick}>
              COMMENCEZ
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accueil;
