import React, { useState } from 'react';
import { IoMdArrowDropright } from 'react-icons/io';
import Header1 from './Header1';
const DevisD = () => {

    // Données fictives pour l'utilisateur connecté
    const currentUser = {
        name: 'John Doe',
        email: 'john.doe@example.com',
    };

    // Affiche le formulaire de devis impression/demande
  

    // Gère la soumission du formulaire
    const handleSubmitDevis = (event) => {
        event.preventDefault();
        alert('Devis envoyé avec succès !');
    };

    return (
        <div className="bg-white min-h-screen">
            {/* Header */}
            <Header1 />

            <div className="container mx-auto px-4 py-8">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                    {/* Sidebar */}
                    <div className="bg-white shadow-md rounded-lg p-4">
                        <ul className="space-y-4">
                            <li className="group">
                                <a href="/compte-diffuseur"  className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Tableau de bord
                                </a>
                            </li>
                            <li className="group">
                                <a href="/livres" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Mes livres
                                </a>
                            </li>
                            <li className="group">
                                <a href="/rechercher-auteurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Trouver des professionnels
                                </a>
                            </li>
                            <li className="group">
                                <a href="/information-diffuseur" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Mes Informations
                                </a>
                            </li>
                            <li className="group">
    <a href="/devis-diffuseur" className="font-bold text-gray-600 border border-green-500 rounded-md px-2 py-2 flex items-center">
    <span className="inline-block text-green-500 mr-2">
      <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
    </span>
      Devis Impression / demande
    </a>
  </li>
                            <li className="group">
                                <a href="/factures" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Mes factures                                </a>
                            </li>
    
  <li className="group">
                                <a href="/book-sales" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Mes Ventes
                                </a>
                            </li>
                 <li className="group">
                                <a href="/commandes" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
                                    <span className="hidden group-hover:inline-block text-green-500 mr-2">
                                        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
                                    </span>
                                    Commandes
                                </a>
                            </li>
                         
                        </ul>
                    </div>

                    {/* Main Content */}
                    <div className="md:col-span-3">
                        <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
                            <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500 w-3/4">DEMANDE DE DEVIS</h2>

                            {/* Formulaire de devis impression/demande */}
                                <form >
                                    <div className="mb-4">
                                        <label className="block font-bold mb-2 text-[#737373]">Type de format :</label>
                                        <input
                                            type="text"
                                            className="border border-gray-300 p-2 w-full rounded-md"
                                            placeholder="Entrez le type de format"
                                            required
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block font-bold mb-2 text-[#737373]">Type d'impression :</label>
                                        <input
                                            type="text"
                                            className="border border-gray-300 p-2 w-full rounded-md"
                                            placeholder="Entrez le type d'impression"
                                            required
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block font-bold mb-2 text-[#737373]">Quantités :</label>
                                        <input
                                            type="text"
                                            className="border border-gray-300 p-2 w-full rounded-md"
                                            placeholder="Entrez la quantité"
                                            required
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block font-bold mb-2 text-[#737373]">Description :</label>
                                        <textarea
                                            className="border border-gray-300 p-2 w-full rounded-md"
                                            placeholder="Décrivez votre demande"
                                            required
                                        ></textarea>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block font-bold mb-2 text-[#737373]">Nom de L'auteur :</label>
                                        <input
                                            type="text"
                                            className="border border-gray-300 p-2 w-full rounded-md"
                                            defaultValue={currentUser.name}
                                            readOnly
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block font-bold mb-2 text-[#737373]">Email de L'auteur :</label>
                                        <input
                                            type="email"
                                            className="border border-gray-300 p-2 w-full rounded-md"
                                            defaultValue={currentUser.email}
                                            readOnly
                                        />
                                    </div>

                                    <div className="flex justify-end">
                                        <button
                                            type="submit"
                                            className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                                        >
                                            Envoyer
                                        </button>
                                    </div>
                                </form>
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DevisD;
