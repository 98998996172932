import React, { useState } from 'react';
import { IoMdCreate, IoMdTrash, IoMdArrowDropright } from 'react-icons/io';
import logo from '../img/logo_editions7_transparent.png';
import chat from '../img/chat.png';
import Header1 from './diffuseurs/Header1';

const Livre = () => {
    const [showModal, setShowModal] = useState(false); // État pour afficher le modal d'ajout
    const [showEditModal, setShowEditModal] = useState(false); // État pour afficher le modal d'édition
    const [selectedBook, setSelectedBook] = useState({}); // Stocke les données du livre à éditer

    const bookData = {
        title: "Mon Livre",
        description: "Ceci est la description du livre.",
        price: "20€",
        salesTarget: "500 exemplaires",
        coverImage: "Aucune image sélectionnée",
    };

    // Affiche le modal d'ajout de livre
    const handleAddBook = () => {
        setShowModal(true);
    };

    // Affiche le modal d'édition avec les données du livre sélectionné
    const handleEditBook = (book) => {
        setSelectedBook(book); // Stocke les données du livre sélectionné
        setShowEditModal(true); // Ouvre le modal d'édition
    };

    const handleSubmitBook = (event) => {
        event.preventDefault();
        // Logique pour soumettre le formulaire d'ajout
        alert("Livre ajouté avec succès !");
        setShowModal(false); // Fermer le modal après la soumission
    };

    const handleSubmitEditBook = (event) => {
        event.preventDefault();
        // Logique pour soumettre les modifications
        alert("Livre modifié avec succès !");
        setShowEditModal(false); // Fermer le modal après la soumission
    };

    const handleCloseModal = () => {
        setShowModal(false); // Fermer le modal d'ajout
    };

    const handleCloseEditModal = () => {
        setShowEditModal(false); // Fermer le modal d'édition
    };

    return (
        <div className="bg-white min-h-screen">
            {/* Header */}
            <Header1 />

            <div className="container mx-auto px-4 py-8">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                         {/* Sidebar */}
         <div className="bg-white shadow-md rounded-lg p-4">
<ul className="space-y-4">
  <li className="group">
    <a href="/mon-compte" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Tableau de bord
    </a>
  </li>
  <li className="group">
    <a href="/mes-livres" className="font-bold text-gray-600 border border-green-500 rounded-md px-2 py-2 flex items-center">
    <span className="inline-block text-green-500 mr-2">
      <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
    </span>
      Mes livre
    </a>
  </li>
  <li className="group">
    <a href="/recherchre-diffuseurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Trouver des professionnels
    </a>
  </li>
  <li className="group">
    <a href="/mes-informations" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
Mes Informations    </a>
  </li>
  <li className="group">
    <a href="/mes-publications" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Mes publications
    </a>
  </li>
  <li className="group">
    <a href="/devis" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Devis Impression / demande
    </a>
  </li>
  <li className="group">
    <a href="/communication" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Conseil communication
    </a>
  </li>
  <li className="group">
    <a href="/book-sales" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Vente de livres
    </a>
  </li>
  
</ul>


</div>

                    {/* Main Content */}
                    <div className="md:col-span-3">
                        <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
                            <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500 w-3/4">MES LIVRES</h2>

                            {/* Bouton Ajouter un livre */}
                            <div className="flex justify-end mb-4">
                                <button
                                    className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                                    onClick={handleAddBook}
                                >
                                    Ajouter un livre
                                </button>
                            </div>

                            {/* Tableau affichant les données du livre */}
                            <table className="table-auto border-collapse border border-gray-400 w-full">
                                <thead>
                                    <tr className="bg-gray-200">
                                        <th className="border border-gray-300 p-2">Nom du livre</th>
                                        <th className="border border-gray-300 p-2">Description</th>
                                        <th className="border border-gray-300 p-2">Prix</th>
                                        <th className="border border-gray-300 p-2">Objectif de vente</th>
                                        <th className="border border-gray-300 p-2">Couverture</th>
                                        <th className="border border-gray-300 p-2">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="border border-gray-300 p-2">{bookData.title}</td>
                                        <td className="border border-gray-300 p-2">{bookData.description}</td>
                                        <td className="border border-gray-300 p-2">{bookData.price}</td>
                                        <td className="border border-gray-300 p-2">{bookData.salesTarget}</td>
                                        <td className="border border-gray-300 p-2">{bookData.coverImage}</td>
                                        <td className="border border-gray-300 p-2 flex space-x-2">
                                            <button
                                                className="bg-yellow-500 text-white px-2 py-1 rounded-lg hover:bg-yellow-600 flex items-center"
                                                onClick={() => handleEditBook(bookData)} // Ouvre le modal d'édition avec les données du livre
                                            >
                                                <IoMdCreate className="mr-1" />
                                                Modifier
                                            </button>
                                            <button
                                                className="bg-red-500 text-white px-2 py-1 rounded-lg hover:bg-red-600 flex items-center"
                                                onClick={() => alert('Supprimer le livre')}
                                            >
                                                <IoMdTrash className="mr-1" />
                                                Supprimer
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal d'ajout */}
            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
                        <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500 w-3/4">Ajouter un nouveau livre</h2>
                        <form onSubmit={handleSubmitBook}>
                            <div className="mb-4">
                                <label className="block font-bold mb-2 text-[#737373]">Nom du livre :</label>
                                <input
                                    type="text"
                                    className="border border-gray-300 p-2 w-full rounded-md"
                                    placeholder="Entrez le nom du livre"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-[#737373] font-bold mb-2">Description :</label>
                                <textarea
                                    className="border border-gray-300 p-2 w-full rounded-md"
                                    placeholder="Entrez la description du livre"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-[#737373] font-bold mb-2">Prix :</label>
                                <input
                                    type="number"
                                    className="border border-gray-300 p-2 w-full rounded-md"
                                    placeholder="Entrez le prix du livre"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-[#737373] font-bold mb-2">Objectif de vente sur 1 an :</label>
                                <input
                                    type="text"
                                    className="border border-gray-300 p-2 w-full rounded-md"
                                    placeholder="Entrez l'objectif de vente"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-[#737373] font-bold mb-2">Couverture (1ère et 4ème) :</label>
                                <input
                                    type="file"
                                    className="border border-gray-300 p-2 w-full rounded-md"
                                    accept="image/*"
                                    required
                                />
                            </div>
                            <div className="flex justify-end space-x-4">
                                <button
                                    type="button"
                                    className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
                                    onClick={handleCloseModal}
                                >
                                    Annuler
                                </button>
                                <button
                                    type="submit"
                                    className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                                >
                                    Ajouter
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Modal d'édition */}
            {showEditModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
                        <h2 className="text-xl font-bold mb-4">Modifier le livre</h2>
                        <form onSubmit={handleSubmitEditBook}>
                            <div className="mb-4">
                                <label className="block text-[#737373] font-bold mb-2">Nom du livre :</label>
                                <input
                                    type="text"
                                    className="border border-gray-300 p-2 w-full rounded-md"
                                    defaultValue={selectedBook.title} // Valeur par défaut pré-remplie
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-[#737373] font-bold mb-2">Description :</label>
                                <textarea
                                    className="border border-gray-300 p-2 w-full rounded-md"
                                    defaultValue={selectedBook.description} 
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-[#737373] font-bold mb-2">Prix :</label>
                                <input
                                    type="number"
                                    className="border border-gray-300 p-2 w-full rounded-md"
                                    defaultValue={selectedBook.price} 
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-[#737373] font-bold mb-2">Objectif de vente sur 1 an :</label>
                                <input
                                    type="text"
                                    className="border border-gray-300 p-2 w-full rounded-md"
                                    defaultValue={selectedBook.salesTarget} 
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-[#737373] font-bold mb-2">Couverture (1ère et 4ème) :</label>
                                <input
                                    type="file"
                                    className="border border-gray-300 p-2 w-full rounded-md"
                                    accept="image/*"
                                />
                            </div>
                            <div className="flex justify-end space-x-4">
                                <button
                                    type="button"
                                    className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
                                    onClick={handleCloseEditModal}
                                >
                                    Annuler
                                </button>
                                <button
                                    type="submit"
                                    className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                                >
                                    Modifier
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Livre;
